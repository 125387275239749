import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch,connect} from "react-redux";
import Editor from "@monaco-editor/react";
import {updateClientConf, updateScript,clearFormFields,updateMetric} from "../../components/util/redux";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Input, Select,InputNumber} from "antd";
import {getCustomForms, getFormFields} from "../../components/util/redux/asyncActions";
import CryptoJS from "crypto-js";
import {AuthContext} from "../../components/Auth/AuthProvider";
import RemedyEditor from "../../components/util/monaco-remedy";
const _ = require('lodash');
const {Option} = Select;


function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
const select = (state)=>{
    return {
        controlMetrics:state.request.controlMetrics,
        customForms:state.request.customForms,
        loading:state.request.controlMetricsLoading,
        formFields:state.request.formFields,

    }
}
const MetricDetails = (props) =>{
    const {controlMetrics,loading,formFields,customForms}=props;
    const [addForm] = Form.useForm();
    addForm.resetFields();

    //const nameValue = Form.useWatch('name', addForm);
    //console.log(nameValue);
    const dispatch = useDispatch();


    const {metric}=useParams();
    const [metricDetails,setMetricDetails]=React.useState()
    const [showFields,setShowFields]=React.useState()
    const [formName,setFormName]=React.useState()

    const [fieldsHash,setFieldsHash]=React.useState()
    const userManager = useContext(AuthContext);
    const navigate=useNavigate();

    const createHash = (array) => {
        const text = JSON.stringify(array); // Array in String umwandeln
        const hash = CryptoJS.SHA256(text); // SHA-256 Hash erstellen
        return hash.toString(CryptoJS.enc.Hex); // Als Hex-String formatieren
    };

    useEffect(() => {


        if (formFields && formFields.length>0){


         setFieldsHash( createHash(formFields))

        }






    }, [formFields]);

    React.useEffect(
        ()=>{


            if(metric && controlMetrics){



                setMetricDetails(controlMetrics.find(f=>f.name===metric))


            }


        },[metric,controlMetrics]
    )
    useEffect(() => {

        if (formName){

            dispatch(getFormFields({form:formName,userManager,navigate}));

        }






    }, [formName,metricDetails]);
    React.useEffect(
        ()=>{

            if (metricDetails){

                setFormName(metricDetails.form)
                if (metricDetails.type==="value"){
                    setShowFields(true)
                }else{
                    setShowFields(false)
                }
            }




        },[metricDetails]
    )


    useEffect(() => {

        if (!customForms || customForms.length === 0 ){
            dispatch(getCustomForms({userManager,navigate}));
        }



    }, []);





    return (
        <div style={{height:"100%",width:"100%", background:"white"}}>
            <div style={{color:"white"}}>.</div>

            {
                metricDetails &&
                (
                    <Form
                        form={addForm}
                        name={"smilecontrol_metric_"}
                        layout="inline"

                        style={{marginLeft: "15px"}}
                        onFieldsChange={(_,allFields) => {

                            const newConf = {...metricDetails, [_[0].name[0]]:_[0].value}

                            if (newConf.name && newConf.type && newConf.form ) {
                                dispatch(updateMetric({...metricDetails, [_[0].name[0]]:_[0].value}))
                            }

                            if (_[0].name[0]==="type") {
                                setMetricDetails({...metricDetails,type:_[0].value})
                                if(_[0].value==="value") {

                                    setShowFields(true)
                                }else {
                                    setShowFields(false)
                                }
                            }

                            if (_[0].name[0]==="form") {

                                dispatch(clearFormFields());
                                setMetricDetails({...metricDetails,form:_[0].value})


                            }

                        }}
                        initialValues={  { ...metricDetails}}




                    >
                        <Form.Item
                            name="name"
                            label="Metric Name"

                            rules={[{ required: true, message: 'Please enter the metric name !' },{
                                pattern: /^[a-zA-Z0-9_-]*$/, // Keine Leerzeichen, Sonderzeichen oder Umlaute
                                message:
                                    "Input can only contain letters (a-z, A-Z), numbers (0-9), underscores (_), and hyphens (-). No spaces or special characters are allowed.",
                            }]}
                        >
                            <Input  placeholder="enter the name "  style={{width: 300, float: "right", margin: "0 15px 15px 0"}} />


                        </Form.Item>
                        <Form.Item
                            name="form"
                            label="Form Name"
                            rules={[{required: true, message: 'Please select the Formname!'}]}
                        >
                            <Select
                                showSearch

                                style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                                placeholder="select a field"

                                optionFilterProp="children"
                            >
                                {
                                    customForms && Array.isArray(customForms) &&
                                    customForms.map(f => {

                                        return {name:  f}
                                    }).sort((a, b) => (isNaN(a.name) && isNaN(b.name) ? (a.name || '').localeCompare(b.name || '') : a.name - b.name)).map(f =>
                                        (                                <Option key={f.name} value={f.name}>{f.name } </Option>)
                                    )
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="interval"
                            label="Interval"
                            rules={[{required: true, message: 'Please enter the interval!'}]}
                        >
                            <InputNumber
                                min={30}

                                style={{width: 100, float: "right", margin: "0 15px 15px 0"}}





                            />


                        </Form.Item>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[{required: true, message: 'Please select the Type!'}]}
                        >
                            <Select
                                showSearch
                                style={{width: 100, float: "right", margin: "0 15px 15px 0"}}

                                placeholder="select a type"
                                optionFilterProp="children"
                            >                          <Option key="count" value="count">Count</Option>
                                <Option key="value" value="value">Value</Option>
                                <Option key="license" value="license">License</Option>



                            </Select>
                        </Form.Item>
                        {
                            showFields && formFields && formFields.length>0 &&
                            (
                                <Form.Item
                                    name="field"
                                    label="Field"
                                    rules={[{required: true, message: 'Please select the Form Field!'}]}
                                >
                                    <Select
                                        showSearch
                                        style={{width: 500, float: "right", margin: "0 15px 15px 0"}}
                                        placeholder="select a field"
                                        optionFilterProp="children"



                                    >
                                        <>
                                            {
                                                formFields &&
                                                Object.keys(formFields).map(f => {

                                                    return {fieldId: formFields[f].fieldId, name: formFields[f].name}
                                                }).sort((a, b) => (isNaN(a.name) && isNaN(b.name) ? (a.name || '').localeCompare(b.name || '') : a.name - b.name)).map(f =>
                                                    (                                <Option key={f.fieldId} value={f.name}>{f.name } </Option>)
                                                )
                                            }
                                        </>
                                    </Select>
                                </Form.Item>

                            )
                        }

                        {metricDetails && formName && fieldsHash && (
                            <Form.Item
                                name="query"
                                label="Query"
                                rules={[{required: true, message: 'Please enter the query!'}]}
                            >

                                <RemedyEditor
                                    key={"-bq-editor"}
                                    height={"50vh"}
                                    width={"100vw"}
                                    client={metric+"_"+fieldsHash}
                                    config={"metric_" + fieldsHash  }
                                    formFields={formFields}
                                    form={formName}

                                    //setConfig(JSON.parse(config))}
                                />
                            </Form.Item>
                        )}



                    </Form>)
            }
        </div>
    )
}


export default connect(select)(MetricDetails);

